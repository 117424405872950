import React, { useEffect } from "react"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import useAppContext from "~/hooks/useAppContext"

export default function () {
  const { scenes } = useDesignEditorContext()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const editor = useEditor()
  const { isMobile, isTablet } = useAppContext()

  useEffect(() => {
    const updateTemplate = async () => {
      
      if (editor) {
        const updatedTemplate = editor.scene.exportToJSON()
        const updatedPreview = await editor.renderer.render(updatedTemplate)
  
        const updatedPages = scenes.map((p) => {
          if (p.id === updatedTemplate.id) {
            return { ...updatedTemplate, preview: updatedPreview }
          }
          return p
        }) as any[]
  
        setScenes(updatedPages)
      }
    }
  
    updateTemplate()
  }, [])

  const handleNext = () => {
    if (currentIndex < scenes.length - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  return (
    <Block
      $style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: isMobile || isTablet ? "column" : "row",
        overflow: "hidden",
        maxWidth: "100vw",
        maxHeight: "100vh",
      }}
    >
      <button onClick={handlePrevious} disabled={currentIndex === 0} style={{ marginBottom: isMobile ? "10px" : "0" }}>
        Previous
      </button>
      {scenes.length > 0 && (
        <img
          width={isMobile ? "100%" : "auto"}
          height={isMobile ? "auto" : "100%"}
          src={scenes[currentIndex].preview}
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
      <button onClick={handleNext} disabled={currentIndex === scenes.length - 1} style={{ marginTop: isMobile ? "10px" : "0" }}>
        Next
      </button>
    </Block>
  );
}
