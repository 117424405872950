import React, { useState } from "react"
import { Modal, ModalBody, SIZE, ROLE, ModalFooter } from "baseui/modal"
import useEditorType from "~/hooks/useEditorType"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import { Button } from "baseui/button"
import Cropper from 'react-easy-crop'
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import Loading from "~/components/Loading"
import useAppContext from "~/hooks/useAppContext"
import { decode } from 'base64-arraybuffer'


interface ComponentProps {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
}
export default function ({ isOpen, setIsOpen }: ComponentProps) {
  const { cropImage } = useDesignEditorContext()
  const editor = useEditor()
  const { supabase } = useAppContext()

  const aspectRatio = (cropImage.width * cropImage.scaleX) / (cropImage.height * cropImage.scaleY);
  const imageUrl = cropImage.metadata.originalImageUrl;

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [loading, setLoading] = useState(false)

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const cropComplete = async () => {
    const croppedImageBase64: any = await getCroppedImg(
      imageUrl, // Ensure imageUrl is defined and accessible
      croppedAreaPixels // Ensure croppedAreaPixels is defined and accessible
    );
  
    await replaceImage(croppedImageBase64);

    await editor.frame.updateCroppedImage(cropImage, imageUrl);
    setIsOpen(false);

  };


  async function replaceImage(base64Image) {
    setLoading(true);
    const buffer = decode(base64Image.split(",")[1]);
    const path = imageUrl.split('projects/')[1];
    const { data, error } = await supabase.storage.from('images').update(`projects/${path}`, buffer);
  
    if (error) {
      console.error("Error uploading image:", error);
      setLoading(false);
      return null;
    }
  }
  

  async function getCroppedImg(imageSrc, pixelCrop) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Set canvas size to match the cropped area
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
  
        // Draw the cropped image onto the canvas
        ctx.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        );
  
        // Convert the canvas to a base64 string and resolve the promise with it
        const base64Image = canvas.toDataURL('image/jpeg');
        resolve(base64Image);
      };
      image.onerror = () => {
        reject(new Error('Image load error'));
      };
    });
  }
  


  return (
    <Modal
        onClose={() => setIsOpen(false)}
        closeable={loading ? false : true}
        isOpen={isOpen}
        animate
        autoFocus
        size={"auto"}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <Block >

          {
            loading ?
            <Block $style={{
              width: "20vw", 
              height: "20vh", 
              maxWidth: "20vh",
              maxHeight: "20vh",
              overflow: "hidden",
              position: "relative"
          }}>
             <Loading /> 
            </Block>:
            <Block $style={{
              width: "60vw", 
              height: "60vh", 
              maxWidth: "60vh",
              maxHeight: "60vh",
              overflow: "hidden",
              position: "relative"
          }}>
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              />

          </Block>
          }
          
        </Block>
        
        {
          loading ? null :
          <ModalFooter>
        <Block $style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingBottom: "2rem", paddingTop: "2rem" }}>
            <Button onClick={cropComplete} style={{ width: "190px" }}>
                Crop
            </Button>
        </Block>
        </ModalFooter>
        }
        
      </Modal>
  )
}
