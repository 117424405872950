type Layer = {
    id: string;
    name: string;
    angle?: number;
    stroke?: string;
    strokeWidth?: number;
    left: number;
    top: number;
    width: number;
    height: number;
    opacity?: number;
    originX?: string;
    originY?: string;
    scaleX?: number;
    scaleY?: number;
    type: string;
    flipX?: boolean;
    flipY?: boolean;
    skewX?: number;
    skewY?: number;
    visible?: boolean;
    shadow?: any;
    preview?: string;
    src?: string;
    cropX?: number;
    cropY?: number;
    fill?: string;
    metadata?: any;
  };
  
  type Frame = {
    width: number;
    height: number;
  };
  
  type Design = {
    id: string;
    layers: Layer[];
    name?: string;
    frame?: Frame;
    preview?: string
  };

  type Template = {
    id: string;
    name: string;
    frame: Frame;
    scenes: Design[];
  };
  
export function scaleLayouts(designs: Design[], newFrame: Frame): Design[] {
    return designs.map(design => {
      const currentFrame = design.frame;
      const scaleX = newFrame.width / currentFrame.width;
      const scaleY = newFrame.height / currentFrame.height;
  
      const scaledLayers = design.layers.map(layer => ({
        ...layer,
        left: layer.left * scaleX,
        top: layer.top * scaleY,
        scaleX: layer.scaleX ? layer.scaleX * scaleX : 1,
        scaleY: layer.scaleY ? layer.scaleY * scaleY : 1
      }));
  
      return {
        ...design,
        layers: scaledLayers,
        frame: newFrame,
      };
    });
}

export function scaleTemplate(template: Template, newFrame: Frame): Template {
    const currentFrame = template.frame;
    const scaleX = newFrame.width / currentFrame.width;
    const scaleY = newFrame.height / currentFrame.height;
    
    const scaledScenes = template.scenes.map(scene => {
        const scaledLayers = scene.layers.map(layer => ({
            ...layer,
            left: layer.left * scaleX,
            top: layer.top * scaleY,
            scaleX: layer.scaleX ? layer.scaleX * scaleX : 1,
            scaleY: layer.scaleY ? layer.scaleY * scaleY : 1,
        }));

        return {
            ...scene,
            layers: scaledLayers,
            frame: newFrame,
        };
    });

    return {
        ...template,
        scenes: scaledScenes,
        frame: newFrame,
    };
}