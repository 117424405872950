export const BASE_ITEMS = [
  {
    id: "templates",
    name: "Templates",
  },
  {
    id: "backgrounds",
    name: "Backgrounds",
  },
  {
    id: "uploads",
    name: "Uploads",
  },
  {
    id: "text",
    name: "Text",
  }
]

export enum PanelType {
  TEMPLATES = "Templates",
  BACKGROUND = "Background",
}
