import React, { useEffect, useState } from "react"
import { Modal, ModalBody, SIZE, ROLE, ModalFooter, ModalHeader, ModalButton } from "baseui/modal"
import useEditorType from "~/hooks/useEditorType"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import { Button } from "baseui/button"
import Cropper from 'react-easy-crop'
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { Checkbox } from "baseui/checkbox"
import { KIND as ButtonKind } from "baseui/button";
import { set } from "lodash"

interface ComponentProps {
  isOpen: boolean
  setIsOpen: (v: boolean) => void,
  total: number,
  setTotal: (v: number, x: string) => void,
  next: (x: boolean, y: boolean) => void,
}
export default function ({ isOpen, setIsOpen, total, setTotal, next }: ComponentProps) {
  const editor = useEditor()

  const [wibalinChecked, setWilabinChecked] = React.useState(false);
  const [bagChecked, setBagChecked] = React.useState(false);
  const [totalExtras, setTotalExtras] = React.useState(total);
  
  useEffect(() => {
    setTotalExtras(total);
  }, [total]);

  function checkOption(type: string, checked: boolean): void {
    if (type === "wibalin") {
      setWilabinChecked(checked);

      if (checked) {
        setTotal(130, "wibalin");
      } else {
        setTotal(-130, "wibalin");
      }
    } else {
      setBagChecked(checked);

      if (checked) {
        setTotal(95, "bag");
      } else {
        setTotal(-95, "bag");
      }
    }
  }

  function gotoNext() {
    next(wibalinChecked, bagChecked);
  }

  function close() {
    setIsOpen(false);
  }

  return (
    <Modal
        onClose={() => setIsOpen(false)}
        closeable={true}
        isOpen={isOpen}
        animate
        autoFocus
        size={"auto"}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <ModalHeader>Checkout Extras</ModalHeader>
        <Block >

          <Block $style={{
            width: "60vw", 
            height: "60vh", 
            maxWidth: "60vh",
            maxHeight: "60vh",
            overflow: "hidden",
            position: "relative"
        }}>
          <div style={{margin: "30px"}}>
            <Checkbox checked={wibalinChecked} onChange={() => checkOption("wibalin",!wibalinChecked)}>
              Black wibalin box: R130
            </Checkbox>
            <br></br>
            <Checkbox checked={bagChecked} onChange={() => checkOption("bag",!bagChecked)}>
              Linen bag: R95
            </Checkbox>
            
          </div>
          
          </Block>
          
        </Block>

        <ModalFooter>
        <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
          Order Total: R{totalExtras}
        </span>
        <Block $style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>
        <ModalButton kind={ButtonKind.tertiary} onClick={close}>Cancel</ModalButton>
        <ModalButton onClick={gotoNext}>Next</ModalButton>
        </Block>
        </ModalFooter>
      </Modal>
  )
}
