import { PanelType } from "~/constants/app-options";
import React, { createContext, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import ApiService from "~/services/api";

type Template = any;
interface IAppContext {
    supabase: any;
    apiService: any;
    isMobile: boolean | undefined;
    setIsMobile: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    isTablet: boolean | undefined;
    setIsTablet: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    templates: Template[];
    setTemplates: (templates: Template[]) => void;
    uploads: any[];
    setUploads: (templates: any[]) => void;
    shapes: any[];
    setShapes: (templates: any[]) => void;
    activePanel: PanelType;
    setActivePanel: (option: PanelType) => void;
    activeSubMenu: string | null;
    setActiveSubMenu: (option: string) => void;
    currentTemplate: any;
    setCurrentTemplate: any;
    skuData: any;
    setSkuData: (any) => void;
}

export const AppContext = createContext<IAppContext>({
    supabase: null,
    apiService: null,
    isMobile: false,
    setIsMobile: () => {},
    isTablet: false,
    setIsTablet: () => {},
    templates: [],
    setTemplates: () => {},
    uploads: [],
    setUploads: () => {},
    shapes: [],
    setShapes: () => {},
    activePanel: PanelType.TEMPLATES,
    setActivePanel: () => {},
    activeSubMenu: null,
    setActiveSubMenu: (value: string) => {},
    currentTemplate: {},
    setCurrentTemplate: {},
    skuData: {},
    setSkuData: () => {},
});

export function AppProvider({ children }: { children: React.ReactNode }) {
    const supabase = createClient("https://igalgzepbejgggxvyder.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImlnYWxnemVwYmVqZ2dneHZ5ZGVyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk1MjM5NzcsImV4cCI6MjAzNTA5OTk3N30.spwnsmuetBcgN4DyAyTxHpUtIVzEgNyF6KvJnZkldf4");
    const apiService = ApiService;
    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
    const [isTablet, setIsTablet] = useState<boolean | undefined>(undefined);
    const [templates, setTemplates] = useState<Template[]>([]);
    const [uploads, setUploads] = useState<any[]>([]);
    const [shapes, setShapes] = useState<Template[]>([]);
    const [activePanel, setActivePanel] = useState<PanelType>(PanelType.TEMPLATES);
    const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);
    const [skuData, setSkuData] = useState<any>({});
    const context = {
        supabase,
        apiService,
        isMobile,
        setIsMobile,
        isTablet,
        setIsTablet,
        templates,
        setTemplates,
        activePanel,
        setActivePanel,
        shapes,
        setShapes,
        activeSubMenu,
        setActiveSubMenu,
        uploads,
        setUploads,
        currentTemplate,
        setCurrentTemplate,
        skuData,
        setSkuData,
    };
    return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
