import React from "react"
import { Block } from "baseui/block"
import { useActiveObject, useEditor, useFrame } from "@layerhub-io/react"
import { Button, KIND, SIZE } from "baseui/button"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "~/constants/design-editor"

export default function () {
  const [state, setState] = React.useState({ fill: "#000000" })
  const editor = useEditor()
  const frame = useFrame()
  const activeObject = useActiveObject() as any
  const {
    scenes,
    currentScene,
    setScenes,
    setCurrentScene,
    setCurrentDesign,
  } = useDesignEditorContext()

  const deletePage = async () => {
    const updatedScenes = scenes.filter((scene) => scene.id !== currentScene.id)

    if (updatedScenes[0]) {
      setScenes(updatedScenes)
    } else {
      const defaultTemplate = getDefaultTemplate({
        width: frame.width,
        height: frame.height,
      })

      await editor.scene.importFromJSON(defaultTemplate)
      setCurrentDesign({
        id: nanoid(),
        frame: defaultTemplate.frame,
        metadata: {},
        name: "Untitled Design",
        preview: "",
        scenes: [],
        type: "GRAPHIC",
      })
      const initialDesign = editor.scene.exportToJSON() as any
      const preview = await editor.renderer.render(initialDesign)
      setCurrentScene({ ...initialDesign, preview: preview, duration: 5000 })
      setScenes([{ ...initialDesign, preview: preview, duration: 5000 }])
    }
  }

  React.useEffect(() => {
    if (editor) {
      setState({ fill: editor.canvas.backgroundColor as string })
    }
  }, [editor])

  React.useEffect(() => {
    let watcher = async () => {
      setState({ fill: editor.canvas.backgroundColor as string })
    }
    if (editor) {
      editor.on("canvas:updated", watcher)
    }
    return () => {
      if (editor) {
        editor.off("canvas:updated", watcher)
      }
    }
  }, [editor, activeObject])

  return (
    <Block
      $style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        padding: "0 12px",
        justifyContent: "space-between",
      }}
    >
      <Block>
      </Block>
      <Block>
        <Button
          onClick={deletePage}
          size={SIZE.compact}
          kind={KIND.tertiary}
        >
          Delete Page
        </Button></Block>
    </Block>
  )
}
