import React, { useCallback, useEffect } from "react"
import { styled, ThemeProvider, DarkTheme } from "baseui"
import { Theme } from "baseui/theme"
import { Button, KIND } from "baseui/button"
import Logo from "~/components/Icons/Logo"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import Play from "~/components/Icons/Play"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import useEditorType from "~/hooks/useEditorType"
import { IScene } from "@layerhub-io/types"
import { loadTemplateFonts } from "~/utils/fonts"
import { loadVideoEditorAssets } from "~/utils/video"
import DesignTitle from "./DesignTitle"
import { IDesign } from "~/interfaces/DesignEditor"
import Github from "~/components/Icons/Github"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useAppContext from "~/hooks/useAppContext"
import Loading from "~/components/Loading"
import Extras from "../Extras/Extras"
import ErrorModal from "../ErrorModal/ErrorModal"

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  height: "64px",
  background: $theme.colors.black,
  display: "grid",
  padding: "0 1.25rem",
  gridTemplateColumns: "280px 1fr 480px",
  alignItems: "center",
}))

export default function () {
  const { setDisplayPreview, setScenes, setCurrentDesign, currentDesign, scenes } = useDesignEditorContext()
  const editorType = useEditorType()
  const editor = useEditor()
  const { skuData } = useAppContext() 
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const [loading, setLoading] = React.useState(false)
  const [total, setTotal] = React.useState(0)
  let graphic = {}

  const { extrasModalOpen, setExtrasModalOpen, errorModalOpen, setErrorModalOpen} = useDesignEditorContext()

  const isLoggedIn = localStorage.getItem("customerId") === null ? false : true;

  useEffect(() => {
    getPriceTotal()
  }, [scenes.length])
  
  const { apiService } = useAppContext()

  const parseGraphicJSON = () => {
    if (editor) {
      const currentScene = editor.scene.exportToJSON()

      const updatedScenes = scenes.map((scn) => {
        if (scn.id === currentScene.id) {
          return {
            id: currentScene.id,
            layers: currentScene.layers,
            name: currentScene.name,
          }
        }
        return {
          id: scn.id,
          layers: scn.layers,
          name: scn.name,
        }
      })

      if (currentDesign) {
        const graphicTemplate: IDesign = {
          id: currentDesign.id,
          type: "GRAPHIC",
          name: currentDesign.name,
          frame: currentDesign.frame,
          scenes: updatedScenes,
          metadata: {},
          preview: "",
        }

        graphic = graphicTemplate
        return graphicTemplate
      } else {
        console.log("NO CURRENT DESIGN")
      }
    }
  }

  const openModal = () => {

    const graphic = parseGraphicJSON();
    const scenesTotal = graphic.scenes.length - 1;
    const multiples = skuData?.extra_pages_multiple;

    if (scenesTotal > skuData?.standard_page_size && scenesTotal % multiples !== 0 && multiples > 1) {
      setErrorModalOpen(true)
      return
    }

    setExtrasModalOpen(true)
  }

  const setTotalFromExtras = (amount, extra) => {
    console.log("Total: ", amount, extra)
    setTotal(total + amount)

    if (extra === "wibalin") {
      setTotal(total + amount)
    } else {
      setTotal(total + amount)
    }

    localStorage.setItem("extras", JSON.stringify({ wibalin: amount, bag: amount }))
  }

  const exportScenesToPdf = async () => {
    const currentScene = editor.scene.exportToJSON()

    const updatedScenes: IScene[] = scenes.map((scn) => {
      if (scn.id === currentScene.id) {
        return currentScene
      }
      return scn
    })

    const widthInMillimeters = pixelsToMillimeters(currentScene.frame.width);
    const heightInMillimeters = pixelsToMillimeters(currentScene.frame.height);
    
    const pdf = new jsPDF({
      orientation: widthInMillimeters > heightInMillimeters ? 'landscape' : 'portrait',
      unit: 'mm',
      format: [widthInMillimeters, heightInMillimeters]
    });
    
    for (const [index, scene] of updatedScenes.entries()) {
      await editor.scene.importFromJSON(scene);

      const imageUrl = await editor.renderer.render(scene);
      
      if (index > 0) {
        pdf.addPage([widthInMillimeters, heightInMillimeters],
        widthInMillimeters > heightInMillimeters ? 'landscape' : 'portrait',
        );
      }

      if (typeof imageUrl === 'string' && imageUrl.startsWith('data:image')) {
        const format = imageUrl.split(';')[0].split('/')[1];
        pdf.addImage(imageUrl, format, 0, 0, widthInMillimeters, heightInMillimeters, undefined, 'FAST');
      } else if (imageUrl instanceof HTMLCanvasElement) {
        pdf.addImage(imageUrl, 'PNG', 0, 0, widthInMillimeters, heightInMillimeters, undefined, 'FAST');
      }
    }

    pdf.save('scenes.pdf'); 
}

  const pixelsToMillimeters = (pixels, dpi = 300) => (pixels / dpi) * 25.4;


  const exit = (isFromAddingCart = false) => {
    //clear activeDesignId and photobookSku cookies
    document.cookie = `activeDesignId=;domain=.memoryboxco.co.za;path=/`;
    document.cookie = `activeDesignId=;domain=localhost;path=/`;
    document.cookie = `photobookSku=;domain=.memoryboxco.co.za;path=/`;
    document.cookie = `photobookSku=;domain=localhost;path=/`;
    localStorage.removeItem('extras');

    //window.location.href = 'https://memoryboxco.co.za';
    let url = 'https://memoryboxco.co.za';
    if (isFromAddingCart) {
      url += '/cart';
    }
    window.location.href = url;

  }


  const makeDownload = (data: Object) => {
    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data))
    const a = document.createElement("a")
    a.href = dataStr
    a.download = "template.json"
    a.click()
  }

  const makeDownloadTemplate = async () => {
    if (editor) {
      const graphic = parseGraphicJSON()
      /* const currentScene = editor.scene.exportToJSON()

      //map layers print left and top fields only as json
      const layers = currentScene.layers.map((layer) => {
        return {
          left: layer.left,
          top: layer.top,
        }
      }) */
      //console.log("currentScene", currentScene)
      //console.log(layers)
      //return
      makeDownload(graphic)
    }
  }

 

  const loadPresentationTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      await loadTemplateFonts(loadedScene)
      scenes.push({ ...loadedScene, preview })
    }
    return { scenes, design }
  }


  const handleImportTemplate = React.useCallback(
    async (data: any) => {
      let template
      template = await loadGraphicTemplate(data)
      //   @ts-ignore
      setScenes(template.scenes)
      //   @ts-ignore
      setCurrentDesign(template.design)
    },
    [editor]
  )

  const loadGraphicTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)
      await loadTemplateFonts(loadedScene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      scenes.push({ ...loadedScene, preview })
    }

    return { scenes, design }
  }

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (res) => {
        const result = res.target!.result as string
        const design = JSON.parse(result)
        handleImportTemplate(design)
      }
      reader.onerror = (err) => {
        console.log(err)
      }

      reader.readAsText(file)
    }
  }

  async function next(isWibalinChecked: boolean, isBagChecked: boolean) {
    setExtrasModalOpen(false)
    console.log("Next", isWibalinChecked, isBagChecked)
    //addToCart()
    if (isWibalinChecked || isBagChecked)
      localStorage.setItem("extras", JSON.stringify({ wibalin: isWibalinChecked, bag: isBagChecked }))

    addToCart()
  }

  async function addToCart(): Promise<void> {
    setLoading(true);
    try {
      const graphic = parseGraphicJSON();
  
      let firstScene = graphic.scenes[0];
      firstScene.frame = graphic.frame;
  
      const preview = (await editor.renderer.render(firstScene)) as string;
      //graphic.preview = preview
  
      //count the number of scenes
      const scenesTotal = graphic.scenes.length;
  
      const { cartId, itemId } = await apiService.addToCart();

      const design = {
        design: graphic,
        extras: JSON.stringify(localStorage.getItem("extras"))
      }
  
      await apiService.updateCartItemDesign(cartId, itemId, design);
  
      await apiService.updateItemPrice(cartId, itemId, total);
      // add logic to update totals if the pages on the cart are more than standard

      exit(true);
    } catch (error) {
      console.error('Error adding to cart:', error);
      // Optionally, you can show an error message to the user here
    } finally {
      setLoading(false);
    }
  }

  function getPriceTotal(): number {
    if (editor) {
      const graphic = parseGraphicJSON();
      const scenesTotal = graphic.scenes.length - 1;
      if (scenesTotal > skuData?.standard_page_size) {
        const additionalPages = scenesTotal - skuData?.standard_page_size;
        const additionalPagesPrice = (additionalPages / skuData?.extra_pages_multiple) * skuData?.price_per_page;
        const total = skuData?.price + additionalPagesPrice;
        setTotal(total)
        return parseFloat(total.toFixed(2));
      }

    }
    setTotal(parseFloat((skuData?.price || 0).toFixed(2)))
    return parseFloat((skuData?.price || 0).toFixed(2));
  }

   
  const autoSaveDesign = useCallback(async () => {
    if (currentDesign) {
      const graphic = parseGraphicJSON();

      if (graphic) {
        localStorage.setItem('currentSessionDesign', JSON.stringify(graphic));
        localStorage.removeItem('savedProject');
      } else {
        console.log("Auto-save failed: No graphic to save");
      }
    }
  }, [parseGraphicJSON]);

  useEffect(() => {
    const interval = setInterval(autoSaveDesign, 10000)
    return () => clearInterval(interval)
  }, [autoSaveDesign])

  return (
    
    <>{
      <ErrorModal 
        isOpen={errorModalOpen} 
        setIsOpen={setErrorModalOpen} 
        multiplier={skuData?.extra_pages_multiple}/>}{
      <Extras 
        isOpen={extrasModalOpen} 
        setIsOpen={setExtrasModalOpen} 
        total={total} 
        setTotal={setTotalFromExtras} 
        next={next}/>}
      {
        //@ts-ignore
        <ThemeProvider theme={DarkTheme}>
        <Container>
          <div style={{ color: "#ffffff", width: "125px" }}>
            <Logo size={23} />
          </div>
          {isLoggedIn ? <DesignTitle /> : <div></div>}
          <Block $style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "max-content" }}>
            <input
              multiple={false}
              onChange={handleFileInput}
              type="file"
              id="file"
              ref={inputFileRef}
              style={{ display: "none" }}
            />
            {/*
            <Button
            size="compact"
            onClick={handleInputFileRefClick}
            kind={KIND.tertiary}
            overrides={{
              StartEnhancer: {
                style: {
                  marginRight: "4px",
                },
              },
            }}
          >
            Import
          </Button>

          <Button
            size="compact"
            onClick={makeDownloadTemplate}
            kind={KIND.tertiary}
            overrides={{
              StartEnhancer: {
                style: {
                  marginRight: "4px",
                },
              },
            }}
          >
            Export
          </Button>
             <Button
              size="compact"
              onClick={exportScenesToPdf}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              PDF Export
            </Button> */}
            {/* <Button
              size="compact"
              onClick={handleInputFileRefClick}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              Import
            </Button> */}
  
            <Button
              size="compact"
              //onClick={makeDownloadTemplate}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              R {total}
            </Button>
            {
              loading ? <
                Loading color="#fff" height={50}/> : 
                <Button
                  size="compact"
                  onClick={openModal}
                  kind={KIND.tertiary}
                  overrides={{
                    StartEnhancer: {
                      style: {
                        marginRight: "4px",
                      },
                    },
                  }}
                >
                  Add To Basket
                </Button>
            }
            <Button
              size="compact"
              onClick={() => setDisplayPreview(true)}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              Preview
            </Button>
            <Button
              size="compact"
              onClick={() => exit()}
              kind={KIND.tertiary}
              overrides={{
                StartEnhancer: {
                  style: {
                    marginRight: "4px",
                  },
                },
              }}
            >
              Exit
            </Button>
          </Block>
  
        </Container>
      </ThemeProvider>
      }
      
    </>
    
    
  )
}
