function Logo({ size }: { size: number }) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="125px" height="70px" viewBox="0 0 300.000000 69.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.10, written by Peter Selinger 2001-2011
</metadata>
<g transform="translate(0.000000,69.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M400 590 c-18 -26 -71 -134 -109 -224 -17 -41 -24 -27 -12 25 16 69
14 99 -7 99 -22 0 -52 -52 -77 -130 -10 -30 -22 -59 -27 -65 -10 -11 -10 -8
22 130 31 134 31 133 20 140 -13 8 -24 -22 -51 -137 -25 -105 -31 -229 -12
-235 7 -3 15 1 17 9 39 143 83 258 98 258 6 0 7 -7 4 -16 -16 -40 -27 -103
-24 -129 5 -49 27 -40 54 22 69 154 115 246 120 240 3 -3 -11 -55 -30 -117
-66 -203 -70 -310 -11 -310 18 0 31 9 43 30 13 23 22 29 38 25 28 -6 53 16 99
85 22 33 36 47 35 35 -5 -54 20 -63 40 -15 16 39 33 50 28 19 -2 -16 3 -25 13
-27 12 -2 27 15 49 53 18 31 34 54 37 52 2 -3 -5 -36 -17 -75 -26 -86 -24
-136 6 -140 16 -2 27 9 51 54 28 53 31 55 38 33 11 -37 35 -59 65 -59 44 0 66
72 35 116 -18 27 -11 34 36 34 l38 0 -6 -66 c-6 -60 -4 -66 12 -63 11 3 32 30
50 67 18 34 41 72 51 83 19 21 19 21 -1 18 -12 -3 -33 -28 -53 -64 l-33 -60 5
40 c3 22 9 57 12 79 6 31 4 38 -7 34 -8 -2 -15 -17 -17 -31 -3 -26 -6 -27 -64
-27 -58 0 -60 -1 -53 -22 23 -71 13 -127 -20 -114 -27 10 -17 117 15 151 25
27 20 46 -8 29 -19 -12 -65 -79 -106 -155 -16 -30 -31 -48 -33 -41 -3 7 7 53
21 103 30 103 31 119 12 119 -15 0 -86 -77 -87 -94 0 -6 -4 -1 -9 12 -13 30
-28 28 -48 -8 -18 -32 -39 -41 -27 -12 14 36 18 82 6 82 -6 0 -11 -6 -11 -13
0 -23 -120 -187 -137 -187 -11 0 -14 6 -9 23 3 12 6 31 6 42 0 14 10 25 30 32
50 19 68 93 22 93 -40 0 -92 -95 -92 -168 0 -14 -12 -41 -26 -60 -23 -29 -28
-32 -36 -18 -12 22 7 149 37 246 38 121 46 160 40 180 -9 28 -20 25 -45 -10z
m130 -202 c0 -14 -30 -48 -42 -48 -5 0 -2 14 7 30 16 30 35 40 35 18z"/>
<path d="M1667 605 c-25 -25 -54 -78 -68 -122 -7 -24 -16 -43 -21 -43 -4 0 -8
27 -8 61 0 44 -3 60 -12 57 -10 -3 -14 -43 -16 -143 -2 -98 -7 -144 -16 -155
-54 -62 7 -171 87 -156 76 14 128 129 97 212 -12 31 -13 30 21 39 26 7 27 6
22 -39 -7 -59 15 -96 57 -96 20 0 33 7 41 21 11 22 8 80 -6 112 -7 16 -3 18
36 15 42 -3 44 -4 47 -38 2 -28 -5 -44 -38 -84 -44 -53 -46 -56 -27 -56 6 0
23 16 36 35 28 42 37 43 45 10 6 -25 45 -43 66 -30 14 9 110 152 110 165 0 23
-17 5 -66 -68 -27 -41 -55 -77 -61 -79 -7 -3 -17 14 -24 36 l-12 41 52 60 c32
36 47 60 38 60 -9 0 -30 -19 -49 -42 l-33 -42 -5 34 c-5 34 -30 55 -30 26 0
-13 -11 -16 -53 -16 -30 0 -56 -3 -59 -6 -3 -3 1 -22 9 -42 16 -42 5 -92 -21
-92 -34 0 -31 108 5 153 23 30 24 37 4 37 -8 0 -22 -11 -31 -25 -9 -13 -32
-29 -50 -34 -29 -8 -40 -6 -68 12 l-33 22 29 35 c39 49 73 147 57 166 -15 18
-34 18 -52 -1z m33 -16 c0 -31 -101 -202 -107 -182 -4 12 38 111 68 160 20 33
39 43 39 22z m-46 -218 c16 -14 14 -17 -26 -37 -24 -13 -46 -23 -51 -24 -6 0
-3 29 8 65 4 16 49 12 69 -4z m28 -63 c3 -64 -4 -104 -25 -143 -18 -32 -29
-41 -53 -43 -41 -4 -74 35 -73 87 0 20 3 30 6 24 10 -27 32 -13 35 23 3 31 9
39 48 60 25 13 48 24 52 24 5 0 9 -15 10 -32z"/>
<path d="M2434 596 c-78 -34 -144 -180 -146 -325 -1 -88 18 -137 56 -147 54
-13 105 24 164 121 33 53 37 57 40 35 5 -36 31 -60 63 -60 47 0 59 47 34 129
-8 23 -7 24 38 17 28 -4 48 -3 52 4 4 6 -16 10 -59 10 -36 0 -66 -3 -66 -6 0
-3 5 -15 11 -26 26 -48 4 -133 -27 -107 -24 20 -17 101 11 144 27 39 27 52 1
42 -15 -6 -37 -37 -97 -141 -50 -86 -106 -146 -135 -146 -85 0 -60 296 35 404
23 26 67 47 85 40 43 -17 15 -107 -42 -136 -18 -9 -29 -19 -23 -22 16 -10 57
11 80 40 45 57 40 115 -10 133 -18 6 -32 11 -33 11 -1 -1 -15 -7 -32 -14z"/>
<path d="M1223 380 c-33 -47 -47 -51 -38 -11 8 38 -12 51 -25 16 -28 -74 3
-108 51 -54 l30 34 -7 -35 c-11 -56 -20 -74 -63 -124 -42 -48 -57 -85 -47
-111 7 -20 49 -19 69 3 15 17 57 130 57 155 0 7 20 35 45 63 24 27 44 52 45
57 0 16 -17 5 -48 -30 l-33 -38 7 58 c8 69 -3 73 -43 17z m-34 -229 c-20 -49
-49 -69 -49 -35 0 21 63 111 67 97 2 -6 -6 -34 -18 -62z"/>
<path d="M2747 274 c-13 -13 -7 -42 10 -48 18 -7 46 20 40 39 -4 12 -40 19
-50 9z"/>
</g>
</svg>

  )
}

export default Logo
