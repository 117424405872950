import React from "react"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import CloudCheck from "~/components/Icons/CloudCheck"
import { StatefulTooltip } from "baseui/tooltip"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import FloppyDisk from "~/components/Icons/Floppy"
import { useEditor } from "@layerhub-io/react"
import { DesignEditorContext } from "~/contexts/DesignEditor"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "~/constants/design-editor"
import { IScene } from "@layerhub-io/types"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { IDesign } from "~/interfaces/DesignEditor"
import useAppContext from "~/hooks/useAppContext"
import Loading from "~/components/Loading"
import Cookies from 'js-cookie';

interface State {
  name: string
  width: number
}


export default function () {
  const [state, setState] = React.useState<State>({ name: "My first design.", width: 0 })
  const inputTitleRef = React.useRef<Input>(null)
  const spanRef = React.useRef<HTMLDivElement | null>(null)
  const editor = useEditor()
  const scenes = useDesignEditorPages()
  const { supabase } = useAppContext()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign } =
    React.useContext(DesignEditorContext)
  const [loading, setLoading] = React.useState(false)

  const handleInputChange = (name: string) => {
    setState({ ...state, name: name, width: spanRef.current?.clientWidth! })
    setCurrentDesign({ ...currentDesign, name })

  }

  React.useEffect(() => {
    setState({ ...state, width: spanRef.current?.clientWidth! + 20 })
  }, [state.name])

  const parseGraphicJSON = () => {
    const currentScene = editor.scene.exportToJSON()

    const updatedScenes = scenes.map((scn) => {
      if (scn.id === currentScene.id) {
        return {
          id: currentScene.id,
          layers: currentScene.layers,
          name: currentScene.name,
        }
      }
      return {
        id: scn.id,
        layers: scn.layers,
        name: scn.name,
      }
    })

    if (currentDesign) {
      const graphicTemplate: IDesign = {
        id: currentDesign.id,
        type: "GRAPHIC",
        name: currentDesign.name,
        frame: currentDesign.frame,
        scenes: updatedScenes,
        metadata: {},
        preview: "",
      }
      return graphicTemplate
    } else {
      console.log("NO CURRENT DESIGN")
    }
  }


  const saveProject = async () => {
    if (editor) {
      const customerId = localStorage.getItem("customerId")
      
      setLoading(true)
      const graphic = parseGraphicJSON()

      // Step 1: Check if a record with the given designId exists
      const { data: existingData, error: selectError } = await supabase
        .from("projects")
        .select("*")
        .eq("design_id", graphic.id)
        .eq("customer_id", customerId)
        .maybeSingle();

      if (selectError) {
      console.error(selectError);
      return;
      }

      if (existingData) {
      // Step 2: Record exists, so update it
      const { error: updateError } = await supabase
        .from("projects")
        .update({
          name: graphic.name,
          design: graphic
        })
        .eq("design_id", graphic.id)
        .eq("customer_id", customerId);

      if (updateError) {
        console.error(updateError);
      }
      } else {
      // Step 3: Record does not exist, so insert a new one
      const { error: insertError } = await supabase
        .from("projects")
        .insert([
          {
            customer_id: customerId,
            sku: Cookies.get('photobookSku'),
            name: graphic.name,
            design: graphic,
            design_id: graphic.id,
            thumbnail: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/991px-Placeholder_view_vector.svg.png"
          },
        ]);

      if (insertError) {
        console.error(insertError);
      }
      }
      setLoading(false)
    }
  }

  return (
    <Block
      $style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#ffffff",
        opacity: 1,
      }}
    >
      <Block $style={{ display: "flex", position: "absolute", top: "-10px", left: "50%", width: "100%" }}>
        <Block
          $style={{
            fontFamily: "Uber Move Text",
            position: "absolute",
            top: "-10px",
            left: "50%",
            fontSize: "14px",
            fontWeight: 500,
          }}
          ref={spanRef}
        >
          {state.name}
        </Block>
      </Block>
      <Block width={`${state.width}px`} display={"flex"}>
        <Input
          onChange={(e: any) => handleInputChange(e.target.value)}
          overrides={{
            Root: {
              style: {
                backgroundColor: "transparent",
                borderTopStyle: "none",
                borderBottomStyle: "none",
                borderRightStyle: "none",
                borderLeftStyle: "none",
              },
            },
            InputContainer: {
              style: {
                backgroundColor: "transparent",
                paddingRight: 0,
              },
            },
            Input: {
              style: {
                fontWeight: 500,
                fontSize: "14px",
                width: `${state.width}px`,
                fontFamily: "Uber Move Text",
                backgroundColor: "transparent",
                color: "#ffffff",
                paddingRight: 0,
              },
            },
          }}
          value={state.name}
          ref={inputTitleRef}
        />
      </Block>

      <StatefulTooltip
        showArrow={true}
        overrides={{
          Inner: {
            style: {
              backgroundColor: "#ffffff",
            },
          },
        }}
        content={() => <Block backgroundColor={"#ffffff"}>{"Save for later"}</Block>}
      >
        <Block
          $style={{
            cursor: "pointer",
            padding: "10px",
            display: "flex",
            color: "#ffffff",
          }}
          onClick={() => saveProject()}
        >
          {loading ? <Loading color="#fff" height={50} /> : <FloppyDisk size={24} />}
        </Block>
      </StatefulTooltip>
    </Block>
  )
}