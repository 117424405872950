//300 DPI

const formatSizes = [{
    "id": 15,
    "name": "Square Layflat 200x200mm",
    "units": "mm",
    "size": {
      "width": 403,
      "height": 203
    },
    "pixels": {
      "width": 2398,
      "height": 2398
    },
    "sku": [
      "LAY200X200",
      "LAY200X200SHDP",
      "LAY200X200SHPP",
      "LAY200X200LHDP",
      "LAY200X200LHPP",
      "LAY200X200PHDP",
      "LAY200X200PHPP"
    ],
    "coverSize": {
      "width": 447,
      "height": 226,
      "pixels": {
        "width": 5413,
        "height": 2661
      },
      "bleed": 10,
      "spine": 5
    }
  },
  {
    "id": 22,
    "name": "Square Layflat 300x300mm",
    "units": "mm",
    "size": {
      "width": 647,
      "height": 326
    },
    "pixels": {
      "width": 3618,
      "height": 3618
    },
    "sku": [
      "LAY300X300SH",
      "LAY300X300LH",
      "LAY300X300PH"
    ],
    "coverSize": {
      "width": 447,
      "height": 326,
      "pixels": {
        "width": 8021,
        "height": 3962
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 308
      }
    }
  },
  {
    "id": 7,
    "name": "A4 Landscape Lay-Flat",
    "units": "mm",
    "size": {
      "width": 583,
      "height": 203
    },
    "pixels": {
      "width": 3575,
      "height": 2551
    },
    "sku": [
      "LAYA4",
      "LAYA4LSH",
      "LAYA4LLH",
      "LAYA4LPH"
    ],
    "coverSize": {
      "width": 627,
      "height": 226,
      "pixels": {
        "width": 7560,
        "height": 3307
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 310
      }
    }
  },
  {
    "id": 25,
    "name": "A4 Portrait Lay-Flat",
    "units": "mm",
    "size": {
      "width": 425,
      "height": 305
    },
    "pixels": {
      "width": 2551,
      "height": 3575
    },
    "sku": [
      "LAYA4P",
      "LAYA4PSHDP",
      "LAYA4PSHPP",
      "LAYA4PLHDP",
      "LAYA4PLHPP",
      "LAYA4PPHDP",
      "LAYA4PPHPP"
    ],
    "coverSize": {
      "width": 467,
      "height": 326,
      "pixels": {
        "width": 5669,
        "height": 3626
      },
      "bleed": 10,
      "spine": 5
    }
  },
  {
    "id": 11,
    "name": "A3 Landscape",
    "units": "mm",
    "size": {
      "width": 845,
      "height": 305
    },
    "pixels": {
      "width": 4843,
      "height": 3575
    },
    "sku": [
      "LAYA3",
      "LAYA3LSH",
      "LAYA3LPH",
      "LAYA3LLH"
    ],
    "coverSize": {
      "width": 887,
      "height": 326,
      "pixels": {
        "width": 10512,
        "height": 5197
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 415
      }
    }
  },
  {
    "id": 33,
    "name": "A4 Landscape",
    "units": "mm",
    "size": {
      "width": 303,
      "height": 216
    },
    "pixels": {
      "width": 3575,
      "height": 2551
    },
    "sku": [
      "A4L",
      "A4LSHMP",
      "A4LSHGFAP",
      "A4LLHMP",
      "A4LLHGFAP",
      "A4LPHMP",
      "A4LPHGFAP",
      "A4LLH"
    ],
    "coverSize": {
      "width": 660,
      "height": 236,
      "pixels": {
        "width": 7560,
        "height": 3307
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 310
      }
    }
  },
  {
    "id": 41,
    "name": "A4 Portrait Photobook",
    "units": "mm",
    "size": {
      "width": 216,
      "height": 303
    },
    "pixels": {
      "width": 2551,
      "height": 3575
    },
    "sku": [
      "A4P",
      "A4PPS",
      "A4PSH",
      "A4PLH",
      "A4PPH"
    ],
    "coverSize": {
      "width": 323,
      "height": 480,
      "pixels": {
        "width": 5669,
        "height": 3626
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 309
      }
    }
  },
  {
    "id": 46,
    "name": "Square Photobooks",
    "units": "mm",
    "size": {
      "width": 120,
      "height": 120
    },
    "pixels": {
      "width": 1417,
      "height": 1417
    },
    "sku": [
      "SP",
      "120X120SPPS",
      "200X200SPPS"
    ],
    "coverSize": {
      "width": 280,
      "height": 140,
      "pixels": {
        "width": 3307,
        "height": 1654
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 125
      }
    }
  },
  {
    "id": 49,
    "name": "Square Photobooks 200x200mm",
    "units": "mm",
    "size": {
      "width": 203,
      "height": 203
    },
    "pixels": {
      "width": 2398,
      "height": 2398
    },
    "sku": [
      "200X200SPSHMP",
      "200X200SPLHMP",
      "200X200SPPHMP"
    ],
    "coverSize": {
      "width": 460,
      "height": 226,
      "pixels": {
        "width": 5413,
        "height": 2661
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 205
      }
    }
  },
  {
    "id": 53,
    "name": "Square Photobooks 300x300mm",
    "units": "mm",
    "size": {
      "width": 306,
      "height": 306
    },
    "pixels": {
      "width": 3618,
      "height": 3618
    },
    "sku": [
      "300X300SPSHMP",
      "300X300SPLHMP",
      "300X300SPPHMP",
      "300X300SPLH"
    ],
    "coverSize": {
      "width": 660,
      "height": 326,
      "pixels": {
        "width": 8021,
        "height": 3962
      },
      "bleed": {
        "width": 3,
        "height": 3
      },
      "spine": {
        "width": 308
      }
    }
  }
]




export default formatSizes