import useEditorType from "~/hooks/useEditorType"
import SelectEditor from "./SelectEditor"
import GraphicEditor from "./GraphicEditor"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import Preview from "./components/Preview"
import ContextMenu from "./components/ContextMenu"
import Cropper from "./components/Cropper/Cropper"

function DesignEditor() {
  const editorType = useEditorType()
  const { displayPreview, setDisplayPreview } = useDesignEditorContext()
  const { cropModalOpen, setCropModalOpen} = useDesignEditorContext()

  return (
    <>
      {displayPreview && <Preview isOpen={displayPreview} setIsOpen={setDisplayPreview} />}
      {cropModalOpen && <Cropper isOpen={cropModalOpen} setIsOpen={setCropModalOpen} />}
      <GraphicEditor />
    </>
  )
}

export default DesignEditor
