import { BrowserRouter, Routes, Route } from "react-router-dom"
import DesignEditor from "~/views/DesignEditor"

function Router() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<DesignEditor />} />
      <Route path="/:id" element={<DesignEditor />} />  
      </Routes>
    </BrowserRouter>
  )
}

export default Router
