import React from "react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import Scrollable from "~/components/Scrollable"
import { Button, SIZE } from "baseui/button"
import DropZone from "~/components/Dropzone"
import { useEditor } from "@layerhub-io/react"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { nanoid } from "nanoid"
import { captureFrame, loadVideoResource } from "~/utils/video"
import { EXAMPLE_UPLOADS } from "~/constants/uploads"
import { createClient } from "@supabase/supabase-js"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import useAppContext from "~/hooks/useAppContext"
import Loading from "~/components/Loading"


const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export default function () {
  const inputFileRef = React.useRef<HTMLInputElement>(null)
  const {uploads, setUploads} = useAppContext()
  const editor = useEditor()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const { currentDesign, setCurrentDesign } = useDesignEditorContext()
  const { supabase } = useAppContext()
  const { id } = currentDesign
  const [files, setFiles] = React.useState<FileList | null>(null);
  const [loading, setLoading] = React.useState(false);

  const baseStorageUrl = `https://igalgzepbejgggxvyder.supabase.co/storage/v1/object/public/images/projects/${id}/`

  //load uploads during initial render
  React.useEffect(() => {
    setLoading(true)
    const fetchUploads = async () => {
      const { data, error } = await supabase
      .storage
      .from('images')
      .list(`projects/${id}`, {
        limit: 100,
        offset: 0,
        sortBy: { column: 'name', order: 'asc' },
      })

      if (error) {
        // Handle error
        console.error("Error listing images",error)
        return
      }

      let imageUrls = []

      data.forEach((image) => {
        //prepend baseStorageUrl to image name
        image.url = baseStorageUrl + image.name
        imageUrls.push({
          id: nanoid(),
          src: image.url,
          preview: image.url,
          type: "StaticImage",
        })
      })

      
      setUploads(imageUrls)
      setLoading(false)
    }
    fetchUploads()
  }, [id])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiles(event.target.files);
  };

  const handleDropFiles = async (files: FileList) => {
    try {
      const newUploads = [];
  
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
  
        // Get file name and prepend to baseStorageUrl
        const name = file.name;
        const url = baseStorageUrl + name;
  
        const upload = {
          id: nanoid(),
          src: url,
          preview: url,
          type: "StaticImage",
        };
  
        newUploads.push(upload);
      }
  
      setUploads([...uploads, ...newUploads]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData("text/plain", event.target.src);
  };
  
  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Add loading spinner to indicate upload in progress
    setLoading(true);
    const files = e.target.files;
    if (!files) return;
  
    const uploadPromises = Array.from(files).map(file => uploadFile(file));
    await Promise.all(uploadPromises);
  
    handleDropFiles(files);
    setLoading(false);
  };

  async function uploadFile(file) {
    const { data, error } = await supabase.storage.from('images').upload(`projects/${id}/${file.name}`, file);
    
    if (error) {
      // Handle error
      console.error("Error uploading",error)
    } else {
      // Handle success
    }
  }

  const addImageToCanvas = (props) => {
    return
    editor.objects.add(props)
  }
  return (
    <DropZone handleDropFiles={handleDropFiles}>
      <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Block
          $style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            justifyContent: "space-between",
            padding: "1.5rem",
          }}
        >
          <Block>Uploads</Block>

          <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
            <AngleDoubleLeft size={18} />
          </Block>
        </Block>
        <Scrollable>
          <Block padding={"0 1.5rem"}>
            <Button
              onClick={handleInputFileRefClick}
              size={SIZE.compact}
              overrides={{
                Root: {
                  style: {
                    width: "100%",
                  },
                },
              }}
            >
              Computer
            </Button>
            <input multiple onChange={handleFileInput} type="file" id="file" ref={inputFileRef} style={{ display: "none" }} />

            <div
              style={{
                marginTop: "1rem",
                display: "grid",
                gap: "0.5rem",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {uploads.map((upload) => (
                <div
                  key={upload.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => addImageToCanvas(upload)}
                >
                  <div>
                    <img draggable='true' width="100%" src={upload.preview ? upload.preview : upload.url}  onDragStart={handleDragStart} alt="preview" />
                  </div>
                </div>
              ))}
              {loading && <Loading />}
            </div>
          </Block>
        </Scrollable>
      </Block>
    </DropZone>
  )
}
